import { defineStore } from 'pinia'
import { useFantasySeasonStore } from './season'

export const useFantasyStore = defineStore('fantasy', {
  getters: {
    game: () => {
      const fantasySeasonStore = useFantasySeasonStore()
      return fantasySeasonStore.data?.game.id
    }
  }
})
