import {
  defaultSimpleStoreOptions,
  defaultStoreOptions
} from '@/utils/pinia/defaults'
import { defineStore } from 'pinia'
import { useApiClient } from '@/composables/api-client'
import { createQuery, createUrl } from '@/utils/pinia/helpers'

export function createSimpleStore(key, options) {
  const storeOptions = Object.assign(
    {},
    defaultStoreOptions,
    defaultSimpleStoreOptions,
    options
  )
  const defaultQuery = Object.assign({}, options.query)

  return defineStore(key, {
    state: () => ({
      data: null,
      fetched: false,
      ...storeOptions?.extends?.state?.()
    }),

    actions: {
      async fetchData(requestQuery = {}) {
        const client = useApiClient()
        const mergedQuery = Object.assign({}, defaultQuery, requestQuery)
        const query = createQuery(storeOptions, mergedQuery)
        const url = createUrl(storeOptions.path)
        const requestOptions = Object.assign({}, { query })
        const result = await client.get(url, requestOptions, {
          cache: storeOptions.cache
        })
        this.fetched = true
        this.data = result

        return result
      },
      async fetchUpdate({ updates = {}, requestQuery = {} } = {}) {
        const client = useApiClient()
        const url = createUrl(options.path)
        const mergedQuery = Object.assign({}, defaultQuery, requestQuery)
        const query = createQuery(storeOptions, mergedQuery)
        const requestOptions = Object.assign(
          {},
          { body: { ...updates }, query }
        )
        const result = await client.put(url, requestOptions)

        this.data = result

        return result
      },
      updateData(updates) {
        Object.keys(updates).forEach(key => {
          this.data[key] = updates[key]
        })
      },
      flush() {
        this.data = null
        this.fetched = false
      },
      ...storeOptions?.extends?.actions
    },

    getters: {
      ...storeOptions.extends?.getters
    }
  })
}
