import { createItemStore } from '@/utils/pinia/index.js'
import { useApiClient } from '@/composables/api-client'

export const useFantasyDayStore = createItemStore('fantasy-day', {
  path: id => `/api/v1/fantasy/season/days/${id}`,
  query: {
    expand: [
      'rewards.resource',
      'memberStatus',
      'positions',
      'slots.cardMember.currentCard.player.team',
      'slots.cardMember.currentCard.labels'
    ]
  },
  extends: {
    actions: {
      async setCard({ dayId, positionId, card }) {
        const apiClient = useApiClient()
        await apiClient.post(`/api/v1/fantasy/season/days/${dayId}/slot`, {
          body: {
            card_member_id: card.id,
            position: positionId
          }
        })

        const slot = this.data.slots.find(slot => slot.position === positionId)
        if (slot) {
          slot.card_member_id = card.id
          slot.cardMember = card
        } else {
          this.data.slots.push({
            card_member_id: card.id,
            position: positionId,
            cardMember: card
          })
        }
      }
    }
  }
})
