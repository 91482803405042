import {
  defaultItemStoreOptions,
  defaultItemStoreQuery,
  defaultStoreOptions
} from '@/utils/pinia/defaults'
import { defineStore } from 'pinia'
import type { UnwrapRef } from 'vue'
import { useApiClient } from '@/composables/api-client'
import { createQuery, createUrl } from '@/utils/pinia/helpers'
import type { AnyFunction } from '~/types'
import type { StoreQuery, ItemStoreOptions } from './types'

export function createItemStore<
  K extends string,
  T extends { [key in K]: string | number },
  S extends Record<string, any> = {},
  G extends Record<string, AnyFunction> = {},
  A extends Record<string, AnyFunction> = {}
>(key: string, options: ItemStoreOptions<T[K], S, G, A>) {
  type State = S & {
    data: T | null
    query: StoreQuery | null
  }

  const storeOptions = Object.assign(
    {},
    defaultStoreOptions,
    defaultItemStoreOptions,
    options
  )
  const defaultQuery = Object.assign({}, defaultItemStoreQuery, options.query)

  return defineStore(key, {
    state: (): State => ({
      data: null,
      query: null,
      ...Object.assign({}, storeOptions?.extends?.state?.())
    }),

    actions: {
      async fetchItem(id: T[K], requestQuery: StoreQuery = {}) {
        const client = useApiClient()
        const mergedQuery = Object.assign({}, defaultQuery, requestQuery)
        const query = createQuery(storeOptions, mergedQuery)
        const url = createUrl(storeOptions.path, id, mergedQuery)
        const requestOptions = Object.assign({}, { query })

        this.data = (await client.get<T>(url, requestOptions, {
          cache: storeOptions.cache
        })) as UnwrapRef<T>

        return this.data
      },
      updateData(updates: Partial<T> = {}) {
        if (this.data) {
          this.data = Object.assign({}, this.data, updates)
        }
      },
      ...Object.assign({}, storeOptions.extends?.actions)
    },

    getters: {
      ...Object.assign({}, storeOptions.extends?.getters)
    }
  })
}
