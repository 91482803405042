<template>
  <article :class="classes" @click="$emit('click')" :data-game="game">
    <div :class="$style.poster" v-if="poster">
      <img :src="poster.large" alt="" />
    </div>

    <div :class="$style.body">
      <div :class="$style.content">
        <div :class="$style.head">
          <div>
            <h3 :class="$style.name">
              <span>{{ name }}</span>
            </h3>
            <p :class="$style.team">
              <span>{{ subName }}</span>
            </p>
          </div>
          <div v-if="icon" :class="$style.icon">
            <img :src="icons[icon]" :alt="icon" />
          </div>
        </div>

        <p :class="$style.label" v-if="label">
          <span>{{ label }}</span>
        </p>

        <ul :class="$style.events">
          <li v-for="(event, i) in events" :key="i" :class="$style.item">
            <span :title="event.name">{{ event.name }}</span>
            <span>+{{ event.rate }}</span>
          </li>
        </ul>
      </div>
    </div>

    <slot />
  </article>
</template>

<script setup>
import csgoSniperIco from '../../assets/types/sniper-icon.svg?url'
import csgoFighterIco from '../../assets/types/fighter-icon.svg?url'
import dotaMainIco from '../../assets/types/icon-type-main.svg?url'
import dotaMiddleIco from '../../assets/types/icon-type-middle.svg?url'
import dotaSupportIco from '../../assets/types/icon-type-support.svg?url'

const $style = useCssModule()
const $emit = defineEmits(['click'])
const $props = defineProps({
  type: {
    type: String,
    default: 'bronze',
    validator: v => ['bronze', 'silver', 'gold'].includes(v)
  },
  poster: {
    type: Object,
    default: null
  },
  icon: {
    type: String,
    default: '',
    validator: v =>
      ['', 'fighter', 'sniper', 'main', 'middle', 'support'].includes(v)
  },
  label: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  subName: {
    type: String,
    default: ''
  },
  events: {
    type: Array,
    default: () => []
  },
  game: {
    type: String,
    default: 'cs'
  }
})

const icons = {
  sniper: csgoSniperIco,
  fighter: csgoFighterIco,
  main: dotaMainIco,
  middle: dotaMiddleIco,
  support: dotaSupportIco
}

const classes = computed(() => [$style.card, $style[$props.type]])
</script>

<style lang="scss" module>
.card {
  --fantasy-card-icon-bg: transparent;

  position: relative;
  min-width: 0;
  border-radius: 1.2em;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.poster {
  position: relative;
  width: 100%;
  padding-bottom: 66%;
  overflow: hidden;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
}

.content {
  border-radius: inherit;
  height: 100%;
}

.head {
  position: relative;
  display: flex;
  justify-content: space-between;
  & > div {
    min-width: 0;
  }
}
.icon {
  align-self: center;
  border-radius: 0.2em;
  background: var(--fantasy-card-icon-bg);
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.name {
  margin-bottom: 0.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    font-weight: 700;
    font-size: 2em;
    line-height: 100%;
    @include down(sm) {
      font-size: 1.5em;
    }
  }
}
.team {
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    color: #ffffff;
    font-weight: 600;
    font-size: 1.4em;
    line-height: 130%;
    opacity: 0.5;
    @include down(sm) {
      font-size: 1.2em;
    }
  }
}
.events {
  list-style: none;
  .item {
    border-radius: 0.4em;
    display: flex;
    justify-content: space-between;
    padding: 0 1em;
    color: var(--card-color-primary);
    background: var(--card-color-primary-05);
    @include down(sm) {
      padding: 0 0.5em;
    }
    span {
      white-space: nowrap;
      min-width: 0;
      font-weight: 400;
      font-size: 1.4em;
      line-height: 2;
      @include down(sm) {
        font-size: 1.2em;
      }
      &:first-child {
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 1em;
        flex: 1 1;
      }
    }
  }
  .item + .item {
    margin-top: 0.6em;
  }
}

.card[data-game='cs'] {
  .content {
    background: linear-gradient(180deg, #252c3e 0%, #000416 45.74%);
  }
  .icon {
    height: 4em;
    @include down(sm) {
      height: 3.2em;
    }
  }
  &.bronze {
    --card-color-primary: #af562f;
    --card-color-primary-0: rgba(175, 86, 47, 0);
    --card-color-primary-05: rgba(175, 86, 47, 0);
  }

  &.silver {
    --card-color-primary: #b8b8b8;
    --card-color-primary-0: rgba(184, 184, 184, 0);
    --card-color-primary-05: rgba(184, 184, 184, 0.05);
  }

  &.gold {
    --card-color-primary: #f3c00b;
    --card-color-primary-0: rgba(243, 192, 11, 0);
    --card-color-primary-05: rgba(243, 192, 11, 0.05);
  }

  &.card {
    background: linear-gradient(
      180deg,
      var(--card-color-primary) 0%,
      var(--card-color-primary-0) 100%
    );
    padding: 1px;
  }

  .body {
    padding: 1px;
    border-radius: inherit;
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1.2em;
    height: 100%;
    background: linear-gradient(
      180deg,
      var(--card-color-primary) 0%,
      var(--card-color-primary-0) 100%
    );
    position: relative;

    .poster + & {
      margin-top: -3.16em;
    }
  }

  .poster {
    background: linear-gradient(180deg, #252c3e 0%, #000416 45.74%);
    border-radius: inherit;
  }

  .label {
    margin-bottom: 1.7em;
    text-align: center;
    padding: 0.5em;
    background: linear-gradient(
      90deg,
      var(--card-color-primary-0) -5.62%,
      var(--card-color-primary) 49.81%,
      var(--card-color-primary-0) 105.23%
    );
    @include down(sm) {
      margin-bottom: 1em;
    }
    span {
      color: #000000;
      font-weight: 400;
      font-size: 1.6em;
      @include down(sm) {
        font-size: 1.2em;
      }
    }
  }
  .events {
    padding: 0 1.6em 2em;
    @include down(sm) {
      padding: 0 0.5em 1em;
    }
  }
  .head {
    padding: 0.8em 1.6em;
    @include down(sm) {
      padding: 0.5em 1.2em;
    }

    & > div:first-child {
      padding-top: 0.8em;
      @include down(sm) {
        padding-top: 0.3em;
      }
    }
  }
}

.card[data-game='dota'] {
  --fantasy-card-icon-bg: #0f1936;

  &.bronze {
    --stat-bg: linear-gradient(180deg, #aa532e 0%, #542b1a 100%);
    --stat-bg-color: #a9522d;
    --stat-text: #630c0a;
  }

  &.silver {
    --stat-bg: linear-gradient(180deg, #dedeed 0%, #808093 100%);
    --stat-bg-color: #ddddec;
    --stat-text: #5b5b6a;
  }

  &.gold {
    --stat-bg: linear-gradient(180deg, #c7a700 0%, #c77700 100%);
    --stat-bg-color: #c7a600;
    --stat-text: #634200;
  }

  .icon {
    width: 4em;
    height: 4em;
    padding: 0.8em;
    @include down(sm) {
      width: 3.2em;
      height: 3.2em;
    }
  }

  &.card {
    padding: 0.8em;
    background: var(--stat-bg);
    display: flex;
    flex-direction: column;
  }

  .body {
    flex: 1;
    border-radius: 0 0 0.8em 0.8em;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .poster {
    border-radius: 0.4em 0.4em 0 0;
    background: #0a1125;
  }

  .label {
    position: absolute;
    left: 50%;
    top: 0;
    padding: 0.6em 0.7em 0.3em;
    border-radius: 0 0 0.8em 0.8em;
    background-color: var(--stat-bg-color);
    transform: translateX(-50%);
    color: var(--stat-text);

    span {
      font-size: 1.2em;
      line-height: 1.2;
      white-space: nowrap;
      @include down(sm) {
        font-size: 1em;
      }
    }
  }

  .head {
    margin: -1px;
    background: #0a1125;
    padding: 1.2em;
  }

  .events {
    padding: 1.2em;
    .item {
      padding: 0;
    }
    @include down(sm) {
      padding: 1em;
    }
  }
}
</style>
