import { createSimpleStore } from '@/utils/pinia/index.js'
import { useApiClient } from '@/composables/api-client'
import { useFantasyCalendarStore } from '@/stores/fantasy/calandar.js'
import { useFantasyDayStore } from '@/stores/fantasy/day.js'

export const useFantasySeasonStore = createSimpleStore('fantasy-season', {
  path: '/api/v1/fantasy/season',
  query: {
    expand: ['rewards.resource', 'memberStatus', 'game']
  },
  extends: {
    actions: {
      async claimSeasonReward() {
        const apiClient = useApiClient()
        await apiClient.post(`/api/v1/fantasy/season/claim-rewards`)
        this.updateData({ can_claim: false })
      },
      async claimDayReward(id) {
        const apiClient = useApiClient()
        const fantasyCalendarStore = useFantasyCalendarStore()
        const fantasyDayStore = useFantasyDayStore()

        await apiClient.post(`/api/v1/fantasy/season/days/${id}/claim-rewards`)

        if (fantasyDayStore.data?.memberStatus) {
          const memberStatus = Object.assign(
            {},
            fantasyDayStore.data.memberStatus,
            {
              rewarded_at: Math.floor(Date.now() / 1000)
            }
          )
          const updatedData = { can_claim: false, memberStatus }
          fantasyCalendarStore.updateItem(id, updatedData)
          fantasyDayStore.updateData(updatedData)
        }
      }
    }
  }
})
